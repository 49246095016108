export default ({ i18n }) => {
  const _i18n = i18n.pages.CheckoutDeliveryInfo;

  return {
    className: null,
    setup: {
      title: "2. ".concat(_i18n.TITLE),
      text: _i18n.ADDRESS_GROUP,
      fields: {
        firstName: _i18n.FIELD_FIRSTNAME,
        lastName: _i18n.FIELD_LASTNAME,
        address1: _i18n.FIELD_ADDRESS1,
        address2: _i18n.FIELD_ADDRESS2,
        postalCode: _i18n.FIELD_POSTALCODE,
        city: _i18n.FIELD_CITY,
        email: _i18n.FIELD_EMAIL,
        phone: _i18n.FIELD_PHONE,
        phoneCountry: _i18n.FIELD_PHONE_COUNTRY,
        altInvoiceAddress: _i18n.FIELD_ALTINVOICEADDRESS
      },
      placeholders: {
        firstName: _i18n.PLACEHOLDER_FIRSTNAME,
        lastName: _i18n.PLACEHOLDER_LASTNAME,
        address1: _i18n.PLACEHOLDER_ADDRESS1,
        address2: _i18n.PLACEHOLDER_ADDRESS2,
        postalCode: _i18n.PLACEHOLDER_POSTALCODE,
        city: _i18n.PLACEHOLDER_CITY,
        email: _i18n.PLACEHOLDER_EMAIL,
        phone: _i18n.PLACEHOLDER_PHONE
      }
    },
    deliveryAddress: {},
    altInvoiceAddress: false,
    invoiceAddress: {}
  };
};
