import { getRoutePathByItem } from "@constants";

export default ({ imgParams, pathfinder, i18n }) => {
  const _i18n = i18n.pages.Campaign;

  const campaignParams = {
    ...imgParams,
    cloudinary: { ...imgParams.cloudinary, path: "campaign" }
  };

  const buildItem = (item, props = {}) => ({
    img: {
      src: campaignParams.imgDir + "/" + item.IMG,
      thumbnail: true,
      fluid: true,
      width: "100%",
      height: "100%",
      title: item.TITLE,
      importance: "high",
      cloudinary: campaignParams.cloudinary,
      sizes: props.sizes,
      aspect: props.aspect || 1,
      video: Boolean(item.VIDEO)
    },
    href: getRoutePathByItem(pathfinder, item)
  });

  const buildButton = button => {
    const href = getRoutePathByItem(pathfinder, button);

    if (!button.TITLE || !button.ENABLED || !href) {
      return false;
    }

    return {
      button: {
        block: true,
        variant: button.VARIANT,
        className: "px-1",
        onClick: e => {},
        href: getRoutePathByItem(pathfinder, button)
      },
      title: button.TITLE,
      icon: {
        transform: "right-2",
        icon: "angle-right"
      }
    };
  };

  const images = {
    carousel: {
      sizes: {
        mobilePortrait: 308,
        mobileLandscape: 473,
        tabletPortrait: 433,
        tabletLandscape: 593,
        desktop: 713
      },
      aspect: 0.823
    },
    rightImg: {
      sizes: {
        mobilePortrait: 308,
        mobileLandscape: 473,
        tabletPortrait: 213,
        tabletLandscape: 293,
        desktop: 353
      },
      aspect: 0.807
    }
  };

  const header =
    _i18n.header.TITLE || _i18n.header.TEXT
      ? { title: _i18n.header.TITLE, text: _i18n.header.TEXT }
      : null;

  const carousel = _i18n.carousel.map(item => buildItem(item, images.carousel));
  const rightImg = _i18n.rightImg.map(item => buildItem(item, images.rightImg));
  const buttons = _i18n.buttons.map(buildButton).filter(Boolean);

  return {
    header,
    carousel,
    rightImg,
    buttons
  };
};
