import { escapeReact } from "@utils/react";

export default ({ i18n, pathfinder }) => {
  const _i18n = i18n.pages.CheckoutPlaceOrder;

  return {
    className: "my-4",
    title: _i18n.TITLE,
    labelAgreeNewsletter: _i18n.LABEL_AGREE_NEWSLETTER,
    labelAgreeBuyTerms: escapeReact(_i18n.LABEL_AGREE_BUY_TERMS, pathfinder),
    value: false,
    onClick: () => {}
  };
};
