import React from "react";
import ImageFootnoteCardDeck from "../../components/CardDeck/Image/Footnote";
import { isMobileDevice, mediaBreakpoint } from "@utils/breakpoints";

export default ({ imgParams, pathfinder, i18n, imageFootnoteCardDeck }) => {
  const _i18n = i18n.pages.MainMenu;

  function transformItem(item) {
    const result = {
      caption: item.TITLE,
      url: item.URL
        ? item.URL.startsWith("/")
          ? pathfinder.prefixRoute(item.URL)
          : /^\w*:\/\/.+/.test(item.URL)
          ? item.URL
          : pathfinder.get(item.URL)
        : item.CATEGORY_SLUG
        ? pathfinder
            .prefixRoute("/product-category/")
            .concat(item.CATEGORY_SLUG)
        : null
    };

    if (Array.isArray(item.items)) {
      result.dropdown = Boolean(item.DROPDOWN);
      result.hasAnchor = result.dropdown && item.ANCHOR;
      result.items = transformItems(item.items);
    }

    const groups = transformGroups(item.groups);

    if (groups) {
      const groupItems = groups.filter(group => group.items);
      if (groupItems.length) {
        result.groups = groupItems;
      }
    }

    if (item.URL) {
      if (/^([\w-]+)$/.test(item.URL)) {
        result.routeName = item.URL;
      } else {
        result.routeName = pathfinder.find(item.URL);
      }
    } else {
      result.routeName = item.URL
        ? pathfinder.get(item.URL)
        : item.CATEGORY_SLUG
        ? pathfinder.find(
            pathfinder.prefixRoute("/product-category/:categoryId")
          )
        : null;
    }

    return result;
  }

  function transformItems(items) {
    if (!(items && Array.isArray(items))) {
      return items;
    }

    return items.map(transformItem);
  }

  function transformGroups(groups) {
    if (!(groups && Array.isArray(groups))) {
      return groups;
    }

    return groups.map(group => {
      return {
        colspan: _i18n.HAS_FOOTNOTE_CARDDECK ? group.COLSPAN : null,
        items: transformItems(group.items)
      };
    });
  }

  const menu = _i18n.items.map(transformItem);

  if (_i18n.HAS_FOOTNOTE_CARDDECK && menu[0].groups) {
    const usedColSpan = menu[0].groups.reduce(
      (carry, group) => carry + (group.colspan || 1),
      0
    );

    menu[0].groups.push({
      colspan: 12 - usedColSpan,
      mediaQuery: mediaBreakpoint.default,
      isFootenoteCardDeck: true,
      items: (
        <ImageFootnoteCardDeck
          items={imageFootnoteCardDeck.items}
          asDropdownItem
          asOverlay={true}
          centered={true}
        />
      )
    });
  }

  return {
    menu,
    autoExpand: _i18n.AUTO_EXPAND && !isMobileDevice()
  };
};
