import GDPRCookieController from "../../json/components/GDPRCookieController.json";
import GetAddress from "../../json/components/GetAddress.json";
import OrderCouponWidget from "../../json/components/OrderCouponWidget.json";
import Product from "../../json/components/Product.json";
import ProductAvailability from "../../json/components/ProductAvailability.json";
import ProductCategoryComparator from "../../json/components/ProductCategoryComparator.json";
import ProductCategoryFavorite from "../../json/components/ProductCategoryFavorite.json";
import ProductCategorySelector from "../../json/components/ProductCategorySelector.json";
import ProductFilterActiveSet from "../../json/components/ProductFilterActiveSet.json";
import ProductFilterCheckboxInput from "../../json/components/ProductFilterCheckboxInput.json";
import ProductSerie from "../../json/components/ProductSerie.json";
import SearchableDropdown from "../../json/components/SearchableDropdown.json";
import ShoppingCart from "../../json/components/ShoppingCart.json";
import SiteRouteProduct from "../../json/components/SiteRouteProduct.json";
import SiteRouteSlider from "../../json/components/SiteRouteSlider.json";

/**
 * @description Handle admin modules loading on non-admin webpack config
 * @returns {Object}
 */
const getAdminJSON = () => {
  try {
    return { AdminToolbox: require("../../json/components/AdminToolbox.json") };
  } catch (error) {
    return {};
  }
};

/**
 * @description Handle user modules loading on non-admin webpack config
 * @returns {Object}
 */
const getUserJSON = () => {
  try {
    return {
      UserLogin: require("../../json/components/UserLogin.json"),
      UserLogout: require("../../json/components/UserLogout.json"),
      UserOrderList: require("../../json/components/UserOrderList.json"),
      UserPaymentList: require("../../json/components/UserPaymentList.json"),
      UserProfile: require("../../json/components/UserProfile.json"),
      UserRegistration: require("../../json/components/UserRegistration.json"),
      UserResetPassword: require("../../json/components/UserResetPassword.json")
    };
  } catch (error) {
    return {};
  }
};

/**
 * @description Handle search-engine modules loading
 * @returns {Object}
 */
const getSearchEngineJSON = () => {
  try {
    return {
      SearchEngine: require("../../json/components/SearchEngine.json")
    };
  } catch (error) {
    return {};
  }
};

export default (siteId, config, mergeSiteConfig) => {
  const obj = {
    ...getAdminJSON(),
    //
    ...getSearchEngineJSON(),
    //
    Product,
    ProductAvailability,
    ProductCategoryComparator,
    ProductCategoryFavorite,
    ProductFilterActiveSet,
    ProductFilterCheckboxInput,
    ProductSerie,
    ProductCategorySelector,
    ShoppingCart,
    OrderCouponWidget,
    GetAddress,
    GDPRCookieController,
    SiteRouteSlider,
    SearchableDropdown,
    SiteRouteProduct,
    //
    ...getUserJSON()
  };

  return mergeSiteConfig(siteId, config, obj, "frontend/i18n/components");
};
