export default ({ paymentMethods, i18n }) => {
  const _i18n = i18n.pages.CheckoutShipmentPayment;

  return {
    className: "my-4",
    setup: { title: _i18n.TITLE },
    shipment: {
      title: _i18n.SHIPMENT_TITLE,
      className: null
    },
    paymentMethods: paymentMethods,
    payment: {
      title: _i18n.PAYMENT_TITLE,
      className: null
    }
  };
};
